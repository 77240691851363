<template>
<div>
  <div class="row">
    <div class="col-md-9">
      <managerticketsoverview class="mb-2" v-if="$hasTenantModule(1)" />
      <ticketsByUsers v-if="$hasTenantModule(1)"/>
      <ticketsByPriority v-if="$hasTenantModule(1)"/>
      <top5AccountTickets v-if="$hasTenantModule(1)"/>
      
      <sharedwithMeQuickAccess v-if="$hasTenantModule(2)"/>
      <managerpeopleoverview v-if="$hasTenantModule(3)"/>
    </div>
    <div class="col-md-3">
      <topDeskPerformer class="mb-2" v-if="$hasTenantModule(1)"/>
      <ticketsstatslist class="mb-2" v-if="$hasTenantModule(1)"/>
      <recentdocsactivity v-if="$hasTenantModule(2)"/>
      <recentmessages></recentmessages>
      <recentnotifications class="mb-2"></recentnotifications >
    </div>
  </div>
</div>

</template>

<script>
import recentnotifications from "@/components/widgets/generic/recentnotifications"
import recentmessages from '@/components/widgets/generic/recentmessages'
import managerpeopleoverview from '@/components/widgets/people/managerpeopleoverview'
import managerticketsoverview from '@/components/widgets/desk/managerticketsoverview'
import sharedwithMeQuickAccess from '@/components/widgets/docs/quickaccess.vue'
import ticketsstatslist from '../../components/widgets/desk/ticketsstatslist.vue'
import recentdocsactivity from '@/components/widgets/docs/recentdocsactivity.vue'
import ticketsByUsers from '@/components/widgets/desk/ticketsByUsers.vue'
import ticketsByPriority from '@/components/widgets/desk/ticketsByPriority.vue'
import top5AccountTickets from '@/components/widgets/desk/top5AccountTickets.vue'
import topDeskPerformer from '@/components/widgets/desk/topDeskPerformer.vue'

export default {
  components: { 
    recentnotifications, 
    recentmessages,
    managerpeopleoverview, 
    managerticketsoverview,  
    ticketsstatslist, 
    sharedwithMeQuickAccess, 
    recentdocsactivity, 
    ticketsByUsers, 
    ticketsByPriority, 
    top5AccountTickets,
    topDeskPerformer }
,
created() {
  this.getDashboard()
},
methods:{
  getDashboard (){ 
     this.$http.get('/ViewAccess/admindashboard')
        .then(() => { 
        })
        .catch(() => { 
        })
  }
}
}
</script>

<style>

</style>